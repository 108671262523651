import React from 'react';
// import { useRouter } from '@/provider/RouterProvider';

interface ConfigDetail {
  [key: string]: any;
}
const PromptConfig: React.FC<{
  // promptId?: number | string | null;
  prompt: string;
  configString: string;
  className?: string;
  classNameConfig?: string;
  // isTemplate?: boolean;
  // isOwner?: boolean;
}> = ({ prompt, configString, className, classNameConfig }) => {
  const configDetail: ConfigDetail = JSON.parse(configString);
  const listpromptParam = [];
  function formatTitile(str: string) {
    const words = str.split('_');

    const result = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

    return result;
  }
  let negativePrompt;
  for (const prop in configDetail) {
    if (configDetail.hasOwnProperty('negative_prompt')) {
      if (prop === 'negative_prompt' && configDetail.negative_prompt.length > 0) {
        negativePrompt = configDetail.negative_prompt;
        delete configDetail.negative_prompt;
      }
    }

    if (configDetail.hasOwnProperty(prop)) {
      if (prop === 'task') {
        listpromptParam.push({ title: formatTitile('Model'), val: configDetail.task });
      } else if (prop === 'model') {
        listpromptParam.push({ title: formatTitile('Model ID'), val: configDetail.model });
      } else {
        listpromptParam.push({ title: formatTitile(prop), val: configDetail[prop] });
      }
    }
  }
  // const router = useRouter();
  return (
    <div className={`inline-block rounded-[20px] bg-white p-[24px] ${className}`}>
      {prompt && (
        <>
          <div className="mb-2 flex justify-between">
            <div className="flex items-center">
              <span className="text-[14px] font-semibold lg:text-[12px] ">Prompt</span>
            </div>
          </div>
          <div>
            <div className="min-h-[100px] w-full rounded-[12px] bg-[#F8F9FD] p-[12px]">
              <span className="font-mono text-[15px] leading-7">{prompt}</span>
            </div>
          </div>
        </>
      )}
      {negativePrompt && (
        <>
          <div className="my-2 flex justify-between">
            <div className="flex items-center">
              <span className="text-[14px] font-semibold lg:text-[12px] ">Negative Prompt</span>
            </div>
          </div>
          <div>
            <div className="min-h-[100px] w-full rounded-[12px] bg-[#F8F9FD] p-[12px]">
              <span className="font-mono text-[15px] leading-7">{negativePrompt}</span>
            </div>
          </div>
        </>
      )}
      {listpromptParam.length >= 1 && (
        <div className="pt-[11px]">
          <div className="pb-2 text-[14px] font-semibold lg:text-[12px] ">Model config</div>
          <div className={`grid grid-cols-3 gap-[11px] lg:grid-cols-2 ${classNameConfig}`}>
            {listpromptParam.map(
              (item) =>
                String(item.val).length > 0 && (
                  <div key={item.title} className="w-full rounded-[12px] bg-[#F8F9FD] p-[12px] ">
                    <div className="font-sora text-14 font-semibold leading-5 text-gray-500">
                      {item.title}
                    </div>
                    <div className="font-mono text-[15px] font-medium leading-6">
                      {String(item.val)}
                    </div>
                  </div>
                ),
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PromptConfig;
