'use client';

import { Divider } from '@nextui-org/react';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { AccordionBaseSingle } from '../accordion';
import RenderWhen from '../RenderWhen';
import ItemComment from './ItemComment';
import useComment from './useCommentTemplate';
import WriteComment from './WriteComment';

const CommentTemplate: React.FC<{
  templateId: number;
}> = ({ templateId }) => {
  const { list, currentPage, loadMoreComments } = useComment(templateId, true);
  // const isDisableComment = post.status === STATUS_POST.DELETE;
  const [isNewHeight, setIsNewHeight] = useState<boolean>(false);
  return (
    <div className="">
      <AccordionBaseSingle
        isNewHeight={isNewHeight}
        openDefault
        className="mb-0 select-none bg-white"
        title={<span className="text-16 font-semibold leading-7">Comment</span>}
      >
        <div className="relative">
          <WriteComment
            templateId={templateId}
            callback={() => {
              loadMoreComments(1);
            }}
            callbackSetHeight={(isOpenList: boolean) => {
              setIsNewHeight(isOpenList);
            }}
          />
          {list.length > 0 && <Divider className="mb-6 bg-gray-100" />}
        </div>
        <RenderWhen>
          <RenderWhen.If isTrue={list.length > 0}>
            <InfiniteScroll
              style={{ overflow: 'hidden' }}
              dataLength={list.length}
              next={() => {
                loadMoreComments(currentPage + 1);
              }}
              hasMore
              loader=""
              scrollableTarget="list-comment"
            >
              <div
                id="list-comment"
                // className="scrollbar-custom max-h-[200px] overflow-y-auto md:max-h-[100px]"
              >
                {list.map((item) => (
                  <ItemComment
                    key={item.id}
                    comment={item}
                    templateId={templateId}
                    // isDisableComment={isDisableComment}
                  />
                ))}
              </div>
            </InfiniteScroll>
          </RenderWhen.If>
        </RenderWhen>
      </AccordionBaseSingle>
    </div>
  );
};

export default CommentTemplate;
