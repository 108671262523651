import Link from 'next/link';
import React, { useState } from 'react';

import type { Mention, Part } from '@/app/chat/group-chat/ItemChat';
import type { CommentPost } from '@/types/comment';
import { getDurationAsTimeFromNow } from '@/utils';

import { IconComment, IconDot, IconReplyComment } from '../assets';
import AvatarPlaceholder from '../avatar-default';
import ListReply from './ListReply';
import WriteComment from './WriteComment';

const ItemComment: React.FC<{
  comment: CommentPost;
  templateId: number;
  isDisableComment?: boolean;
}> = ({ comment, templateId, isDisableComment }) => {
  const [isSeenReply, setIsSeenReply] = useState(false);
  const [isReply, setIsReply] = useState(false);
  const [totalReply, setToltalReply] = useState<number>(comment.totalSubComments);
  const [refreshReply, setRefreshReply] = useState(false);

  const onFinishReply = () => {
    setRefreshReply(!refreshReply);
    setIsSeenReply(true);
    setToltalReply((prev) => prev + 1);
  };
  const parseContent = (content: string, mentions: Mention[]): Part[] => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const mentionRegex = /<@(\d+)>/g;

    // Map user IDs to Mention objects for quick lookup
    const mentionMap: { [key: number]: Mention } = {};
    mentions.forEach((mention) => {
      mentionMap[mention?.id] = mention;
    });

    const parts: Part[] = content.split(urlRegex).flatMap((part: any) => {
      if (part.match(urlRegex)) {
        return [{ type: 'link', value: part }];
      }

      return part.split(mentionRegex).map((subPart: any, index: any) => {
        if (index % 2 === 1) {
          const userId = parseInt(subPart, 10);
          const mention = mentionMap[userId];
          return mention
            ? {
                type: 'mention',
                value: `@${mention?.username}`,
                publicAddress: mention?.publicAddress,
              }
            : { type: 'text', value: `<@${userId}>` };
        }
        return { type: 'text', value: subPart };
      });
    });

    return parts;
  };

  const parsedContent = parseContent(comment.text, comment?.mentions);

  return (
    <div className="mb-4 w-full">
      <div className="flex">
        <div className="h-[40px] w-[40px] relative rounded-[50%]">
          <Link
            href={`/profile/${comment.user?.publicAddress}`}
            className="flex h-full w-full items-center"
          >
            <AvatarPlaceholder 
              src={comment.user?.picture}
              address={comment.user?.publicAddress} 
              className="w-full h-full rounded-full"
              decor={comment.user?.avatarDecoration}
              decorScale={24}
            />
          </Link>
        </div>
        <div className="w-[calc(100%-40px)] pl-2">
          <div className="rounded-[16px] bg-gray-50 p-3">
            <div className="flex items-center gap-1">
              <Link href={`/profile/${comment.user?.publicAddress}`} className="flex items-center">
                <span className="text-[14px] font-semibold text-black">
                  {comment.user?.username}
                </span>
              </Link>
              <IconDot />
              <span className="text-[12px] text-gray-500">
                {getDurationAsTimeFromNow(comment?.createdAt)}
              </span>
            </div>
            <p className="mt-1 text-[14px] text-gray-700 [overflow-wrap:anywhere]">
              {/* {comment.text} */}
              {parsedContent.map((part, index) =>
                part.type !== 'text' ? (
                  part.type === 'link' ? (
                    <a
                      key={index}
                      target="_blank"
                      href={part.value}
                      className="text-gray-700 underline"
                    >
                      {part.value}
                    </a>
                  ) : (
                    <span
                      key={index}
                      className="cursor-pointer font-semibold text-gray-700"
                      onClick={() => window.open(`/profile/${part?.publicAddress}`, '_blank')}
                    >
                      {part.value}
                    </span>
                  )
                ) : (
                  <span key={index}>{part.value}</span>
                ),
              )}
            </p>
          </div>
          <div className="mt-1 flex items-center gap-1">
            <div
              className="flex cursor-pointer items-center gap-1.5"
              onClick={() => setIsSeenReply(!isSeenReply)}
            >
              <IconComment color="#777E90" />
              <span className="text-[14px] text-gray-500">{totalReply} replies</span>
            </div>
            {!isDisableComment && (
              <>
                <IconDot />
                <div
                  className="flex cursor-pointer items-center gap-1.5"
                  onClick={() => setIsReply(!isReply)}
                >
                  <IconReplyComment />
                  <span className="text-[14px] font-normal text-black">Reply</span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {isReply && (
        <div className="ml-[45px] mt-3">
          <WriteComment commentId={comment.id} templateId={templateId} callback={onFinishReply} />
        </div>
      )}
      {isSeenReply && (
        <div className="ml-[45px] mt-3">
          <ListReply templateId={templateId} commentId={comment.id} refreshReply={refreshReply} />
        </div>
      )}
    </div>
  );
};

export default ItemComment;
