import type { NavigateOptions } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import { usePathname, useSearchParams } from 'next/navigation';

import { useRouter } from '@/provider/RouterProvider';

const useCreateQueryString = () => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const createQueryString = (
    name: string | string[],
    value: any,
    options?: NavigateOptions,
    removeName?: string | string[],
  ) => {
    const params = new URLSearchParams(searchParams);

    if (removeName) {
      if (typeof removeName === 'string') {
        params.delete(removeName);
      } else {
        removeName.forEach((item) => params.delete(item));
      }
    }

    if (typeof name === 'string') {
      if (!value || value.toString() === '[]') params.delete(name);
      else params.set(name, value);
    } else {
      name.forEach((key: string, index: number) => {
        if (!value[index] || value[index].toString() === '[]') params.delete(key);
        else params.set(key, value[index]);

        if (value[index] === false) {
          params.set(key, value[index]);
        }
      });
    }

    router.push(`${pathname}?${params.toString()}`, options, { noLoadBar: true });
    // router.refresh();
  };

  return createQueryString;
};

export default useCreateQueryString;
