import { useCallback, useEffect, useRef, useState } from 'react';

import { getListUseCaseSimilarity } from '@/services/usecase';
import type { IUsecaseItem } from '@/types/usecase';

const PAGE_SIZE = 20;

const useUseCaseSimilar = (templateId: number) => {
  const [loading, setLoading] = useState(false);
  const dataRef = useRef([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState<IUsecaseItem[]>([]);

  const getInputParams = useCallback(
    (page: number) => ({
      offset: PAGE_SIZE * (page - 1),
      size: PAGE_SIZE,
      sort: `{"createdAt":"DESC"}`,
      filter: `{"isNsfw":false}`,
    }),
    [templateId],
  );

  const getUseCaseURL = (id: number | string) => `/template/${id}`;

  const handleGetListUseCase = async (page: number) => {
    setLoading(true);
    const res = await getListUseCaseSimilarity(getInputParams(page), templateId);

    if (page === 1) {
      dataRef.current = [];
      setData([]);
    }
    dataRef.current = dataRef.current.concat(res.items);
    setData(dataRef.current);
    setLoading(false);
  };

  const loadMore = (page: number) => {
    setCurrentPage(page);
    handleGetListUseCase(page);
  };

  useEffect(() => {
    handleGetListUseCase(1);
    setCurrentPage(1);
  }, [templateId]);

  return {
    list: data,
    loading,
    loadMore,
    currentPage,
    setCurrentPage,
    getUseCaseURL,
  };
};

export default useUseCaseSimilar;
