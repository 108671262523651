import { Checkbox, CheckboxGroup } from '@nextui-org/react';
import { usePathname, useSearchParams } from 'next/navigation';
import type { Dispatch, SetStateAction } from 'react';
import React, { useCallback, useEffect, useState } from 'react';

import useCreateQueryString from '@/hooks/useCreateQueryString';
import useGetWidth from '@/hooks/useGetWidth';
import useWindowSize from '@/hooks/useWindowSize';
import { getModels } from '@/services';
import { useSQuery } from '@/utils';

const FilterUsecase: React.FC<{
  setOpenFilter?: Dispatch<SetStateAction<boolean>>;
}> = ({ setOpenFilter }) => {
  const createQueryString = useCreateQueryString();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const screenWidth = useGetWidth();
  const { isMobileView, windowSize } = useWindowSize();

  const { data: dataModels } = useSQuery({
    queryKey: ['modelsFilter', 'market'],
    queryFn: () => getModels(30, 0),
    keepPreviousData: true,
  });

  const onChangeModels = useCallback(
    (modelIds: Array<string>) => {
      if (screenWidth < 768) {
        setOpenFilter && setOpenFilter(false);
      }
      createQueryString(['modelIds'], [JSON.stringify(modelIds)], { scroll: false });
    },
    [screenWidth],
  );
  const [prevScrollpos, setPrevScrollpos] = useState<number>(500);

  useEffect(() => {
    if (isMobileView || windowSize.width === 0 || pathname === '/') return;

    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const filterbar = document.getElementById('filterbar');
      if (!filterbar) return;

      if (currentScrollPos > 400) {
        filterbar.style.top = '90px';
        filterbar.style.position = 'fixed';
        filterbar.style.backgroundColor = 'white';
        filterbar.style.transform = 'translateY(0)';
        if (window.innerWidth <= 768) {
          filterbar.style.position = 'static';
          filterbar.style.transform = 'translateY(0)';
          filterbar.style.height = 'fit-content';
          filterbar.style.padding = '0';
          filterbar.style.backgroundColor = 'transparent';
        }

        setPrevScrollpos(currentScrollPos);
      } else {
        filterbar.style.position = 'static';
        filterbar.style.transform = 'translateY(0)';
        filterbar.style.height = 'fit-content';
        filterbar.style.padding = '0';
        filterbar.style.backgroundColor = 'transparent';

        setPrevScrollpos(currentScrollPos);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollpos, isMobileView, windowSize.width, pathname]);

  return (
    <div className="pr-[20px] md:static md:top-0 md:p-4" id="filterbar">
      <p className="m-0 mb-5 text-16 font-semibold leading-6">Model used</p>
      <CheckboxGroup
        defaultValue={
          (searchParams.get('modelIds') &&
            (JSON.parse(searchParams.get('modelIds') ?? '') as any)) ??
          undefined
        }
        onChange={(e) => onChangeModels(e as Array<string>)}
      >
        {dataModels?.data?.items.map((item: any, index: number) => (
          <Checkbox className="flex items-center whitespace-nowrap" key={index} value={item.id}>
            {item.name}
          </Checkbox>
        ))}
      </CheckboxGroup>
    </div>
  );
};

export default FilterUsecase;
