'use client';

import React, { useEffect, useRef, useState } from 'react';

import { useAuth } from '@/provider/UserProvider';

import { IconSendComment } from '../assets';
import AvatarPlaceholder from '../avatar-default';
import ClientOnly from '../client-only';
import MentionUser from '../mention-user';
import useComment from './useCommentTemplate';

const WriteComment: React.FC<{
  templateId: number;
  commentId?: number;
  callback?: () => void;
  callbackSetHeight?: (isOpenList: boolean) => void;
}> = ({ templateId, commentId, callback, callbackSetHeight }) => {
  const [value, setValue] = useState('');
  const inputRef = useRef<any>(null);
  const { auth, isLogin } = useAuth();
  const { onPostComment } = useComment(templateId, false);
  const [listMention, setListMention] = useState<any[]>([]);

  const onFinish = () => {
    inputRef.current.value = '';
    inputRef.current.focus();
    setValue('');
    callback && callback();
  };
  const replaceMentionsWithIds = (content: string): string => {
    return listMention.reduce((acc, user) => {
      const tag = `@${user.name}`;
      const replacement = `<@${user.id}>`;
      return acc.split(tag).join(replacement);
    }, content);
  };

  const onSubmit = () => {
    const contentSubmit: string = replaceMentionsWithIds(value);
    onPostComment({ value: contentSubmit, commentId, callback: onFinish });
  };
  const [isOpenList, setIsOpenList] = useState(false);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const input = event.currentTarget;
    const caretPosition = input.selectionStart;
    const currentValue = input.value;

    if (event.key === 'Enter') {
      onSubmit();
    }
    if (event.key === 'Escape' && isOpenList) {
      setIsOpenList(false);
    }
    if (event.key === ' ' && isOpenList) {
      setIsOpenList(false);
    }
    if (event.key === '@') {
      setIsOpenList(true);
    }
    if (event.key === 'Backspace' && isOpenList) {
      if (caretPosition !== null && caretPosition > 0 && currentValue[caretPosition - 1] === '@') {
        setIsOpenList(false);
      }
    }
  };
  useEffect(() => {
    callbackSetHeight && callbackSetHeight(isOpenList);
  }, [isOpenList]);

  return (
    <>
      <div className="mb-6 flex items-center">
        <ClientOnly>
          {isLogin ? (
            <div className="h-[40px] w-[40px] overflow-hidden rounded-[50%]">
              <AvatarPlaceholder src={auth.user.picture} address={auth.user.publicAddress} />
            </div>
          ) : (
            <></>
          )}
        </ClientOnly>
        <>
          <div
            aria-checked={isLogin}
            className="w-[calc(100%-30px)] pl-0 pr-2 aria-checked:w-[calc(100%-70px)] aria-checked:px-2"
          >
            <input
              name="textComment"
              value={value}
              ref={inputRef}
              onKeyDown={handleKeyDown}
              // onKeyDown={(e) => e.key === 'Enter' && onSubmit()}
              onChange={(e) => setValue(e.target.value)}
              className="h-[40px] w-full rounded-[100px] border-none bg-gray-50 px-[16px] text-[14px] outline-none"
              placeholder="Write a comment..."
            />
          </div>
          <div onClick={onSubmit} className="w-[30px] hover:cursor-pointer">
            <IconSendComment color={value ? '#DA613A' : '#616879'} />
          </div>
        </>
      </div>
      <MentionUser
        content={value}
        isOpenList={isOpenList}
        setContent={setValue}
        setIsOpenList={setIsOpenList}
        inputRef={inputRef}
        setListMention={setListMention}
      />
    </>
  );
};

export default WriteComment;
