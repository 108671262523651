import Link from 'next/link';
import React from 'react';

import type { CommentPost } from '@/types/comment';
import { getDurationAsTimeFromNow } from '@/utils';

import { IconDot } from '../assets';
import AvatarPlaceholder from '../avatar-default';

const ItemReply: React.FC<{
  comment: CommentPost;
}> = ({ comment }) => {
  return (
    <div className="mb-4 w-full">
      <div className="flex">
        <div className="h-[40px] w-[40px] overflow-hidden rounded-[50%]">
          <Link
            href={`/profile/${comment.creator?.publicAddress}`}
            className="flex h-full w-full items-center"
          >
            <AvatarPlaceholder
              src={comment.creator?.picture}
              address={comment.creator?.publicAddress}
            />
          </Link>
        </div>
        <div className="w-[calc(100%-40px)] pl-2">
          <div className="rounded-[16px] bg-gray-50 p-3">
            <div className="flex items-center gap-1">
              <Link
                href={`/profile/${comment.creator?.publicAddress}`}
                className="flex items-center"
              >
                <span className="text-[14px] font-semibold text-black">
                  {comment.creator?.username || 'Unnamed'}
                </span>
              </Link>
              <IconDot />
              <span className="text-[12px] text-gray-500">
                {getDurationAsTimeFromNow(comment?.createdAt)}
              </span>
            </div>
            <p className="mt-1 text-[14px] text-gray-700 [overflow-wrap:anywhere]">
              {comment?.content}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemReply;
