import { COMMENT_TYPE_TINY } from '@/configs/constant';
import { api } from '@/services';
import type {
  IFetchFeedCommentsProps,
  IPostCommentsProps,
  IPostSubCommentsProps,
} from '@/services/feed';

export const serialize = (obj: any) => {
  const str = [];
  for (const p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  return str.join('&');
};

export const fetchFeedCommentsTemplate = async ({
  id,
  size = 10,
  offset = 0,
}: IFetchFeedCommentsProps) => {
  try {
    const combineUrl: any = {};

    if (size) {
      combineUrl.size = size;
    }

    if (!isNaN(offset)) {
      combineUrl.offset = offset;
    }

    const response = await api.get(`feed/comments/${id}?${serialize(combineUrl)}`);

    return [response.data, null];
  } catch (error) {
    return [null, error];
  }
};

export const postCommentTemplate = async ({ postId, text }: IPostCommentsProps) => {
  const data = JSON.stringify({
    id: postId,
    text,
    type: COMMENT_TYPE_TINY.TEMPLATE,
  });
  try {
    const response = await api.post(`/feed/comments/create`, data);
    return [response.data, null];
  } catch (error) {
    return [null, error];
  }
};

export const postReplyCommentTemplate = async ({ text, commentId }: IPostSubCommentsProps) => {
  try {
    const response = await api.post(`/feed/comments/sub-comment`, {
      commentId,
      text,
      type: COMMENT_TYPE_TINY.TEMPLATE,
    });
    return [response.data, null];
  } catch (error) {
    return [null, error];
  }
};
