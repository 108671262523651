import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { useAuth } from '@/provider/UserProvider';
import { fetchFeedReplyComments } from '@/services/feed';
import {
  fetchFeedCommentsTemplate,
  postCommentTemplate,
  postReplyCommentTemplate,
} from '@/templates/Comment';
import type { CommentPost } from '@/types/comment';

interface CommentInput {
  value: string;
  commentId?: number;
  callback?: () => void;
}

const PAGE_SIZE = 20;

const useComment = (templateId: number, isFetch: boolean, commentId?: number) => {
  const { isLogin } = useAuth();
  const listCommentRef = useRef([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [textComment, setTextComment] = useState('');
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<CommentPost[]>([]);
  const [listReply, setListReply] = useState<CommentPost[]>([]);
  const postId = templateId;

  const handlePostComment = async (text: string, callback?: () => void) => {
    const hasStop = !text || !postId || loading;
    if (hasStop) return;
    setLoading(true);
    const [data] = await postCommentTemplate({ postId, text });
    setLoading(false);
    if (data) {
      callback && callback();
    }
  };

  const handlePostCommentReply = async (text: string, commentId: number, callback?: () => void) => {
    const hasStop = !text || !postId || !commentId || loading;
    if (hasStop) return;
    setLoading(true);
    const [data] = await postReplyCommentTemplate({ text, commentId });
    setLoading(false);
    if (data) {
      callback && callback();
    }
  };

  const onPostComment = async ({ value, commentId, callback }: CommentInput) => {
    if (!isLogin) {
      toast.error('Please login to continue!');
      return;
    }
    if (commentId) {
      handlePostCommentReply(value, commentId, callback);
    } else {
      handlePostComment(value, callback);
    }
  };

  const getComments = async (page: number) => {
    if (!postId) return;
    const [data]: any = await fetchFeedCommentsTemplate({
      id: postId,
      size: PAGE_SIZE,
      offset: PAGE_SIZE * (page - 1),
    });
    if (data) {
      if (page === 1) {
        listCommentRef.current = [];
        setList([]);
      }
      listCommentRef.current = listCommentRef.current.concat(data?.items || []);
      setList(listCommentRef.current);
    }
  };

  const getReplyComments = async () => {
    if (!commentId) return;
    const [data]: any = await fetchFeedReplyComments({ id: commentId, size: PAGE_SIZE, offset: 0 });
    if (data) {
      setListReply(data.items);
    }
  };

  const loadMoreComments = (page: number) => {
    setCurrentPage(page);
    getComments(page);
  };

  useEffect(() => {
    if (isFetch) getComments(1);
  }, [isFetch]);

  // useEffect(() => {
  //   getComments(1);
  // }, [templateId]);

  return {
    list,
    onPostComment,
    getReplyComments,
    listReply,
    setTextComment,
    textComment,
    currentPage,
    loading,
    getComments,
    loadMoreComments,
  };
};

export default useComment;
