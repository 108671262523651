import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  Textarea,
} from '@nextui-org/react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import AddTags from '@/app/profile/[address]/template/[id]/AddTags';
import useSetupUsecase from '@/app/profile/[address]/template/[id]/useSetupUsecase';
import { IconClose } from '@/assets';
import { Buttons, Images } from '@/components';
import MaxLengthInput from '@/components/max-length-input';
import type { SelectOptionItem } from '@/components/select/SelectMultipleWithSearch';
import useLoadTag from '@/hooks/useLoadTag';
// import { useRouter } from '@/provider/RouterProvider';
import type { IDataEditUsecase } from '@/services/usecase';
// import { postUnpublishTemplalte } from '@/services/usecase';
import type { ITagUseCase } from '@/types/tags';

const EditTemplate: React.FC<{
  templateId: any;
  imageUrl: string;
  name: string;
  description: string;
  tags: ITagUseCase[];
  isOpen: boolean;
  onOpenChange: () => void;
  onClose: () => void;
}> = ({ templateId, imageUrl, name, description, tags, isOpen, onOpenChange, onClose }) => {
  const [isAddTag, setIsAddTag] = useState(false);
  const [tagSelected, setTagSelected] = useState<SelectOptionItem[]>([]);
  const [currentTags, setCurrentTags] = useState<SelectOptionItem[]>([]);

  const { loadTagOptions } = useLoadTag();
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const nameWatch = watch('name');
  const descriptionWatch = watch('description');

  const isMaxTags = tagSelected.length >= 5;
  // const router = useRouter();

  const getTags = async () => {
    const result = await loadTagOptions('');
    setCurrentTags(result);
  };

  useEffect(() => {
    if (isOpen) {
      getTags();
    }
  }, [isOpen]);

  useEffect(() => {
    const newTags: any = tags.map((tag) => ({
      value: tag.id,
      label: tag.content,
    }));
    setTagSelected(newTags);
  }, [tags]);

  const { handleEditUsecase, loading } = useSetupUsecase();

  // const handelUnPublishTemplate = async (templateId: any) => {
  //   const res = await postUnpublishTemplalte(templateId);
  //   if (res.data.message === 'success') {
  //     toast.success('Successfully unpublished.');
  //   }
  //   router.back();
  // };

  const onSubmit = async (data: any) => {
    if (tagSelected?.some((tag) => tag.label.length >= 20)) {
      return toast.error('Tag must be less than 20 characters!');
    }
    if (!templateId || loading) return;

    const argUsecase: IDataEditUsecase = {
      name: data.name,
      description: data.description,
      useCaseId: typeof templateId === 'string' ? Number(templateId) : templateId,
    };
    await handleEditUsecase(
      argUsecase,
      tagSelected.map((item) => ({ content: item.label })),
    );
    onClose();
  };

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      scrollBehavior="inside"
      placement="center"
      classNames={{ wrapper: 'overflow-hidden' }}
    >
      <ModalContent className="overflow-scroll sm:!m-0">
        {(onClose) => (
          <>
            <ModalBody className="p-5">
              <div className="mx-auto max-w-[500px] text-black">
                <div>
                  <h1 className="text-[24px] font-bold sm:text-[18px]">
                    Edit template #{templateId}
                  </h1>
                </div>
                <div className="mt-6 sm:mt-5">
                  <div className="mb-5">
                    <Images
                      className="max-h-[160px] max-w-[160px] cursor-pointer rounded-lg object-cover"
                      zoom
                      src={imageUrl}
                      alt="template"
                    />
                  </div>
                  <div className="mb-2">
                    <div className="mb-2 text-[14px] font-[600] text-black">
                      Template name <span className="text-red-600">*</span>
                    </div>
                    <div>
                      <Input
                        classNames={{
                          inputWrapper: 'rounded-[100px] bg-gray-50 px-[14px]',
                        }}
                        required
                        maxLength={60}
                        defaultValue={name}
                        placeholder="Suggest a title for this template"
                        {...register('name', {
                          required: 'Name is required',
                        })}
                      />
                      <div
                        className={`mt-1 flex h-[20px] ${
                          errors.name ? 'justify-between' : 'justify-end'
                        }`}
                      >
                        {errors.name && (
                          <div className="text-[13px] text-red-500">
                            {errors.name?.message as string}
                          </div>
                        )}
                        <MaxLengthInput max={500} length={nameWatch?.length || 0} />
                      </div>
                    </div>
                  </div>
                  <div className="mb-2">
                    <div className="mb-2 text-[14px] font-[600] text-black">
                      Description <span className="text-red-600">*</span>
                    </div>
                    <div>
                      <Textarea
                        classNames={{
                          inputWrapper: 'rounded-[16px] bg-gray-50 px-[14px] py-[2px]',
                          label: 'hidden',
                        }}
                        maxLength={500}
                        placeholder="Describe what your template does"
                        {...register('description', {
                          required: 'Description is required',
                        })}
                        defaultValue={description}
                        minRows={5}
                      />
                      <div
                        className={`mt-1 flex h-[20px] ${
                          errors.description ? 'justify-between' : 'justify-end'
                        }`}
                      >
                        {errors.description && (
                          <div className="text-[13px] text-red-500">
                            {errors.description?.message as string}
                          </div>
                        )}
                        <MaxLengthInput max={500} length={descriptionWatch?.length || 0} />
                      </div>
                    </div>
                  </div>
                  <div className="mb-2">
                    <div className="mb-2 text-[14px] font-[600] text-black">Tags</div>
                    <div className="flex flex-wrap items-center gap-[6px]">
                      {tagSelected.map((item) => (
                        <div
                          key={item.value}
                          className="flex h-[34px] items-center gap-1 rounded-full bg-gray-50 px-3 text-[13px] font-semibold"
                        >
                          {item.label}
                          <div
                            onClick={() =>
                              setTagSelected((list) => list.filter((t) => t.value !== item.value))
                            }
                            className="flex h-[18px] w-[18px] cursor-pointer items-center justify-center rounded-sm hover:bg-gray-200"
                          >
                            <IconClose />
                          </div>
                        </div>
                      ))}
                      {isAddTag ? (
                        <AddTags
                          onChange={(tag) => setTagSelected((list) => [...list, tag])}
                          onClose={() => setIsAddTag(false)}
                        />
                      ) : (
                        <Button
                          onClick={() => !isMaxTags && setIsAddTag(true)}
                          aria-hidden={isMaxTags}
                          className="flex h-[34px] items-center rounded-full bg-orange-50 text-orange-500 aria-hidden:cursor-not-allowed aria-hidden:opacity-70"
                        >
                          <span className="text-[19px] font-light">+</span>
                          <span className="font-semibold">Add</span>
                        </Button>
                      )}
                    </div>
                    <div className="mt-4 flex flex-wrap gap-[6px]">
                      {currentTags
                        .filter((item) => !tagSelected.some((t) => t.value === item.value))
                        .map((tag) => (
                          <div
                            key={tag.value}
                            aria-hidden={isMaxTags}
                            onClick={() => !isMaxTags && setTagSelected((item) => [...item, tag])}
                            className="flex h-[34px] cursor-pointer items-center gap-1 rounded-full bg-gray-50 px-3 text-[13px] font-semibold text-gray-500 hover:opacity-80 aria-hidden:cursor-not-allowed aria-hidden:opacity-70"
                          >
                            <span className="text-[20px] font-light">+</span>
                            <span>{tag.label}</span>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Buttons onClick={onClose} typeBtn="cancel" className="h-[44px] w-[136px]">
                Cancel
              </Buttons>
              <Buttons
                isLoading={loading}
                onClick={handleSubmit(onSubmit)}
                className="h-[44px] w-[calc(100%-148px)]"
              >
                Update
              </Buttons>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default EditTemplate;
