import React, { useEffect } from 'react';

import ItemReply from './ItemReply';
import useComment from './useCommentTemplate';

const ListReply: React.FC<{
  templateId: number;
  commentId: number;
  refreshReply: boolean;
}> = ({ templateId, commentId, refreshReply }) => {
  const { listReply, getReplyComments } = useComment(templateId, false, commentId);
  useEffect(() => {
    getReplyComments();
  }, [refreshReply]);

  return (
    <>
      {listReply.map((comment) => (
        <ItemReply key={comment.id} comment={comment} />
      ))}
    </>
  );
};

export default ListReply;
