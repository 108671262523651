import React, { useEffect, useRef, useState } from 'react';

import { getListMention } from '@/services/group-chat';

import AvatarPlaceholder from '../avatar-default';

const MentionUser: React.FC<{
  content: string;
  setContent: React.Dispatch<React.SetStateAction<string>>;
  setListMention: React.Dispatch<React.SetStateAction<any[]>>;
  setIsOpenList: React.Dispatch<React.SetStateAction<boolean>>;
  isOpenList: boolean;
  inputRef: any;
  isRoomChat?: boolean;
}> = ({ content, isOpenList, setContent, setIsOpenList, inputRef, setListMention, isRoomChat }) => {
  const [listSimilarUserName, setListSimilarUserName] = useState<any[]>([]);
  const [textMention, setTextMention] = useState('');
  const [highlightIndex, setHighlightIndex] = useState(-1);
  const listRef = useRef<HTMLUListElement>(null);

  const getRecentMentionText = (content: string) => {
    const matches: any = content.match(/@\w+/g);
    if (matches && matches.length > 0) {
      return matches[matches.length - 1].slice(1);
    }
    return '';
  };

  const handleMentionUser = (info: any) => {
    const lastIndex = content.lastIndexOf('@');
    const newContent = `${content.slice(0, lastIndex + 1) + info.username} `;
    setListMention((prev: any[]) => [...prev, { name: info.username, id: info.id }]);
    setContent(newContent);
    setIsOpenList(false);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handelGetListUser = async () => {
    const res = textMention ? await getListMention(textMention) : await getListMention('a');
    if (res) {
      setListSimilarUserName(res[0].items);
    }
  };

  useEffect(() => {
    setTextMention(getRecentMentionText(content));
  }, [content]);

  useEffect(() => {
    if (textMention) handelGetListUser();
  }, [textMention]);

  useEffect(() => {
    setHighlightIndex(-1);
  }, [isOpenList]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!isOpenList) return;

    if (event.key === 'ArrowDown') {
      event.preventDefault();
      setHighlightIndex((prevIndex) =>
        prevIndex < listSimilarUserName.length - 1 ? prevIndex + 1 : 0,
      );
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      setHighlightIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : listSimilarUserName.length - 1,
      );
    } else if (event.key === 'Enter') {
      event.stopPropagation();
      if (highlightIndex >= 0 && highlightIndex < listSimilarUserName.length) {
        handleMentionUser(listSimilarUserName[highlightIndex]);
      }
    }
  };

  useEffect(() => {
    if (isOpenList) {
      inputRef.current?.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      inputRef.current?.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpenList, highlightIndex, listSimilarUserName]);
  // isOpenList && textMention.length > 0 ?
  return isOpenList && textMention.length > 0 ? (
    <div
      className={`absolute ${
        isRoomChat ? 'bottom-[60px]' : 'top-[50px]'
      } left-0 z-9999 w-full rounded-2xl border-[1px] border-gray-200 bg-white p-2 md:p-0`}
    >
      <ul aria-label="Actions" ref={listRef}>
        {listSimilarUserName.map((item: any, index: number) => (
          <li
            key={item?.id}
            onClick={() => handleMentionUser(item)}
            className={`flex cursor-pointer items-center justify-between p-2 ${
              highlightIndex === index ? 'rounded-lg bg-gray-200' : ''
            }`}
          >
            <div className="flex items-center gap-2">
              <AvatarPlaceholder
                src={item?.picture}
                address={item?.publicAddress}
                className="h-[32px] w-[32px] rounded-[50%] object-cover"
              />
              <p className="max-w-[80%] text-ellipsis text-[14px] font-semibold">
                {item?.username}
              </p>
            </div>
            <div className="text-[14px] text-gray-500">#{item?.id}</div>
          </li>
        ))}
      </ul>
    </div>
  ) : null;
};

export default MentionUser;
