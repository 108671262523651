import { api } from '../axios';

export const serialize = (obj: any) => {
  const str = [];
  for (const p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  return str.join('&');
};

export interface IFetchFeedProps {
  pageSize: number;
  cursor?: string | null;
  sort?: any;
  filter?: any;
}

export interface IPostReactionProps {
  type: 1 | 0;
  postId: number;
  commentId?: number;
}

export interface IFetchFeedFollowingProps {
  size: number;
  cursor?: string | null;
  sort?: any;
  filter?: string;
  offset?: number;
}

export interface IFetchFeedCommentsProps {
  id: number;
  size: number;
  offset: number;
}

export interface IPostSubCommentsProps {
  postId?: number;
  commentId: number;
  text: string;
}

export interface IFetchFeedReplyCommentsProps {
  id: number;
  size: number;
  offset: number;
}
export interface IPostCommentsProps {
  postId: number;
  text: string;
}

export interface IFollowerProps {
  size: number;
  offset: number;
}

export interface IListNFTsProps {
  size: number;
  offset: number;
  address: string;
  keySearch?: string;
}

export interface IPostFollowerProps {
  id: number;
}

export interface IUpdateFeedProps {
  storyId: number;
  text: string;
  event: string;
}

export interface IPostFeedProps {
  assetId: number;
  text: string;
  event: string;
}

export const fetchFeed = async ({ pageSize = 30, cursor, filter }: IFetchFeedProps) => {
  const combineUrl: any = {};

  if (pageSize) {
    combineUrl.size = pageSize;
  }

  if (filter) {
    combineUrl.filter = filter;
  }

  if (cursor) {
    combineUrl.after = cursor;
  }
  return await api.get(`/feed/posts?${serialize(combineUrl)}`);
};

export const fetchFeedFollowing = async ({
  size = 30,
  offset = 0,
  filter,
}: IFetchFeedFollowingProps) => {
  try {
    const combineUrl: any = {};

    if (size) {
      combineUrl.size = size;
    }

    if (filter) {
      combineUrl.filter = `{ action: ${filter} }`;
    }

    if (offset) {
      combineUrl.offset = offset;
    }

    const response = await api.get(`/feed/follow/following?${serialize(combineUrl)}`);
    return [response.data, null];
  } catch (error) {
    return [null, error];
  }
};

export const postReaction = async ({ type, postId }: IPostReactionProps) => {
  try {
    const response = await api.post(`feed/posts/${postId}/reactions`, { type });
    return [response.data, null];
  } catch (error) {
    return [null, error];
  }
};

export const fetchFeedComments = async ({ id, size = 10, offset = 0 }: IFetchFeedCommentsProps) => {
  try {
    const combineUrl: any = {};

    if (size) {
      combineUrl.size = size;
    }

    if (!isNaN(offset)) {
      combineUrl.offset = offset;
    }

    const response = await api.get(`feed/posts/${id}/comments?${serialize(combineUrl)}`);

    return [response.data, null];
  } catch (error) {
    return [null, error];
  }
};

export const fetchFeedReplyComments = async ({
  id,
  size = 10,
  offset = 0,
}: IFetchFeedReplyCommentsProps) => {
  try {
    const combineUrl: any = {};

    if (size) {
      combineUrl.size = size;
    }

    if (!isNaN(offset)) {
      combineUrl.offset = offset;
    }

    const response = await api.get(`comments/model/${id}/sub-comments?${serialize(combineUrl)}`);

    return [response.data, null];
  } catch (error) {
    return [null, error];
  }
};

export const postComment = async ({ postId, text }: IPostCommentsProps) => {
  try {
    const response = await api.post(`/feed/posts/${postId}/comments`, { text });
    return [response.data, null];
  } catch (error) {
    return [null, error];
  }
};

export const postReplyComment = async ({ postId, text, commentId }: IPostSubCommentsProps) => {
  try {
    const response = await api.post(`/feed/comments`, { text, postId, commentId });
    return [response.data, null];
  } catch (error) {
    return [null, error];
  }
};
export const fetchFollower = async ({ size = 3, offset = 0 }: IFollowerProps) => {
  try {
    const response = await api.get(`feed/follow/recommends?size=${size}&offset=${offset}`);
    return [response.data, null];
  } catch (error) {
    return [null, error];
  }
};

export const fetchListNFTs = async ({ size = 9, offset, keySearch, address }: IListNFTsProps) => {
  try {
    const response = await api.get(
      `assets?size=${size}&offset=${offset}&filter=${encodeURIComponent(
        `{"accountAddress":"${address}","name":"${keySearch}"}`,
      )}`,
    );

    return [response.data, null];
  } catch (error) {
    return [null, error];
  }
};

export const postFollower = async ({ id }: IPostFollowerProps) => {
  try {
    const response = await api.post(`/feed/follow/change-status/${id}`);

    return [response.data, null];
  } catch (error) {
    return [null, error];
  }
};

export const updateFeed = async ({ storyId, text, event }: IUpdateFeedProps) => {
  try {
    const response = await api.patch(`feed/posts/${storyId}`, { text, event });
    return [response.data, null];
  } catch (error) {
    return [null, error];
  }
};

export const postFeed = async ({ assetId, text, event }: IPostFeedProps) => {
  try {
    const response = await api.post(`feed/posts`, { assetId, text, event });
    return [response.data, null];
  } catch (error) {
    return [null, error];
  }
};

export const deleteFeed = async ({ id }: any) => {
  try {
    const response = await api.delete(`feed/posts/${id}`);
    return [response.data, null];
  } catch (error) {
    return [null, error];
  }
};
