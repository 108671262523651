import { useState } from 'react';
import { toast } from 'react-toastify';

import { useRouter } from '@/provider/RouterProvider';
import type { IDataEditUsecase, IDataPostUsecase, IPostUsecaseTag } from '@/services/usecase';
import { postEditUsecase, postUsecaseById, postUsecaseTag } from '@/services/usecase';

const useSetupUsecase = () => {
  const [loading, setLoading] = useState(false);
  const router = useRouter();

  const handlePostUsecase = async (
    argUsecase: IDataPostUsecase,
    usecaseTags: { content: string }[],
  ) => {
    setLoading(true);
    try {
      const resUsecase = await postUsecaseById(argUsecase);
      const useCaseId = resUsecase.data.id;
      const isUpdateTags = useCaseId && Array.isArray(usecaseTags);
      if (isUpdateTags) {
        const argAssetTags: IPostUsecaseTag = {
          useCaseId,
          tags: usecaseTags,
        };
        await postUsecaseTag(argAssetTags);
      }
      if (useCaseId) {
        toast.success('Updated template successfully!');
        router.push(`/template/${useCaseId}`);
      }
    } catch (error: any) {
      toast.error(error?.message);
    }
    setLoading(false);
  };
  const handleEditUsecase = async (
    argUsecase: IDataEditUsecase,
    usecaseTags: { content: string }[],
  ) => {
    setLoading(true);
    try {
      const resUsecase = await postEditUsecase(argUsecase);
      const useCaseId = Number(argUsecase.useCaseId);
      const isUpdateTags = useCaseId && Array.isArray(usecaseTags);
      if (isUpdateTags) {
        const argAssetTags: IPostUsecaseTag = {
          useCaseId,
          tags: usecaseTags,
        };
        await postUsecaseTag(argAssetTags);
      }
      if (resUsecase) {
        toast.success('Updated template successfully!');
        window.open(`/template/${useCaseId}`, '_self');
      }
    } catch (error: any) {
      toast.error(error?.message);
    }
    setLoading(false);
  };

  return { handlePostUsecase, handleEditUsecase, loading };
};

export default useSetupUsecase;
