'use client';

import { Divider, Modal, ModalBody, ModalContent, useDisclosure } from '@nextui-org/react';
import mixpanel from 'mixpanel-browser';
import Link from 'next/link';
import type { Dispatch, SetStateAction } from 'react';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { toast } from 'react-toastify';

import { CloseLineHeaderIcon, IconCardTemplateEye, IconRemixHover } from '@/assets';
import { Images } from '@/components';
import { BookMarkIcon, IconHeartNotLiked, IconRun } from '@/components/assets';
import AvatarPlaceholder from '@/components/avatar-default';
import NoData from '@/components/NoData';
import RenderWhen from '@/components/RenderWhen';
import { MIXPANEL_TRACK } from '@/configs/constant';
import useWindowSize from '@/hooks/useWindowSize';
import LoginModal from '@/layouts/header/Login';
import RemixCommon from '@/layouts/remix-common';
import { useRouter } from '@/provider/RouterProvider';
import { getUsecaseById, postIncreaseTotalUse } from '@/services/usecase';
import type { IUsecaseDetail, IUsecaseItem } from '@/types/usecase';
import { formatCash } from '@/utils';

import UsecaseDetail from './[id]/UsecaseDetail';
import FilterUsecase from './FilterUsecase';
import NsfwMark from '@/components/NsfwMark';

const ListUsecase: React.FC<{
  openFilter?: boolean;
  setOpenFilter?: Dispatch<SetStateAction<boolean>>;
  list: IUsecaseItem[];
  loading: boolean;
  currentPage: number;
  loadMore: (page: number) => void;
  isViewInUrl?: boolean;
}> = ({ openFilter, setOpenFilter, list, loading, currentPage, loadMore, isViewInUrl }) => {
  const { isMobileView } = useWindowSize();
  const router = useRouter();
  const [remixConfig, setRemixConfig] = useState<IUsecaseItem | null>(null);
  const useModalRemix = useDisclosure({
    onClose() {
      document.body.style.removeProperty('overflow');
      setRemixConfig(null);
    },
  });
  const { onOpen } = useModalRemix;
  const { isOpen, onOpenChange, onClose } = useDisclosure();
  const {
    isOpen: isOpenViewTemplate,
    onOpen: onOpenViewTemplate,
    onClose: onCloseViewTemplate,
  } = useDisclosure();

  const [dataTemplateToView, setDataTemplateToView] = useState<IUsecaseDetail>();

  const handleOpenModalViewTemplate = async (id: number) => {
    if (isViewInUrl) {
      router.push(`/template/${id}`);
    } else {
      const resUsecase = await getUsecaseById(id);
      if (resUsecase) {
        setDataTemplateToView(resUsecase.data);
        onOpenViewTemplate();
      }
    }
  };

  const handleUseUseCase = (status: number, usecaseId: number, remixConfig: IUsecaseItem) => {
    postIncreaseTotalUse(usecaseId);
    if (status === 4) {
      return toast.error('Model maintenance!');
    }
    mixpanel.track(MIXPANEL_TRACK.REMIX);
    setRemixConfig(remixConfig);
    document.body.style.overflow = 'hidden';
    onOpen();
  };

  return (
    <div className="flex min-h-[400px] w-full">
      {remixConfig && <RemixCommon useModalRemix={useModalRemix} remixConfig={remixConfig} />}
      <div
        aria-expanded={openFilter}
        className="scrollbar-custom relative w-0 overflow-y-auto opacity-0 duration-300 aria-expanded:w-[260px] aria-expanded:opacity-100 md:fixed md:inset-0 md:z-99 md:bg-white md:pr-0 aria-expanded:md:w-full"
      >
        <div className="hidden w-full items-center justify-between p-4 md:flex">
          <span className="text-20 font-semibold">Filters</span>
          <CloseLineHeaderIcon onClick={() => setOpenFilter && setOpenFilter(false)} />
        </div>
        <Divider className="hidden w-full bg-gray-100 md:block" />
        {openFilter && <FilterUsecase setOpenFilter={setOpenFilter} />}
      </div>
      <div aria-expanded={openFilter} className="w-full aria-expanded:w-[calc(100%-260px)]">
        <RenderWhen>
          <RenderWhen.If isTrue={list.length === 0 && !loading}>
            <NoData />
          </RenderWhen.If>
          <RenderWhen.If isTrue={list.length > 0}>
            <InfiniteScroll
              style={{ overflow: 'hidden' }}
              dataLength={list.length}
              next={() => {
                loadMore(currentPage + 1);
              }}
              hasMore
              loader=""
            >
              <ResponsiveMasonry
                columnsCountBreakPoints={{ 350: 2, 750: 2, 900: 3, 1200: 4, 1800: 5 }}
              >
                <Masonry gutter={isMobileView ? '8px' : '16px'}>
                  {list.map((usecase) => (
                    <>
                      <Link
                        key={usecase.id}
                        href={`/template/${usecase.id}`}
                        onClick={(e) => {
                          e.preventDefault();
                          handleOpenModalViewTemplate(usecase.id);
                        }}
                        className="group relative cursor-pointer overflow-hidden rounded-[16px] shadow-lg shadow-[rgba(199,205,231,0.55)]  sm:rounded-[10px]"
                      >
                        <Images
                          src={usecase?.processingAsset?.url}
                          alt="usecase"
                          className="h-full w-full cursor-pointer object-contain duration-300 group-hover:scale-110"
                        />
                        {usecase?.processingAsset?.isNsfw && <NsfwMark url={usecase?.processingAsset?.url} />}
                        <div className="absolute bottom-0 left-0 z-10 h-[100px] w-full bg-gradient-to-t from-black/80 to-black/0" />
                        <div className="absolute inset-x-0 bottom-0 z-10 p-3 lg:p-1">
                          {usecase.name && (
                            <h6 className="truncate text-[16px] font-medium text-white drop-shadow lg:hidden">
                              {usecase.name}
                            </h6>
                          )}
                          <div className="mt-[8px] flex items-center justify-between lg:flex-col lg:items-start lg:justify-start lg:gap-1">
                            <Link
                              className="flex items-center"
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              href={`/profile/${usecase.creator.publicAddress}`}
                            >
                              <div className="h-[18px] w-[18px] rounded-full relative">
                                <AvatarPlaceholder
                                  width={18}
                                  height={18}
                                  src={usecase.creator.picture}
                                  address={usecase.creator.publicAddress}
                                  className="h-full w-fill rounded-full object-cover"
                                  decor={usecase.creator.avatarDecoration}
                                  decorScale={10}
                                />
                              </div>
                              <p className="ml-1 line-clamp-1 max-w-[60px] text-[12px] text-white">
                                {usecase.creator.username || 'Unnamed'}
                              </p>
                            </Link>
                            <div className="flex items-center gap-[6px]">
                              {!!usecase.totalReactions && usecase?.totalReactions > 0 && (
                                <>
                                  <div className="flex items-center">
                                    <IconHeartNotLiked width={16} height={16} color="white" />
                                    <span className="ml-1 text-[12px] text-white">
                                      {formatCash(usecase?.totalReactions)}
                                    </span>
                                  </div>
                                  <span className="h-1 w-1 rounded-full bg-white opacity-60" />
                                </>
                              )}
                              {!!usecase?.totalBookmarks && usecase?.totalBookmarks > 0 && (
                                <>
                                  <div className="flex items-center">
                                    <BookMarkIcon className="scale-[1.2]" />
                                    <span className="ml-1 text-[12px] text-white">
                                      {formatCash(usecase.totalBookmarks)}
                                    </span>
                                  </div>
                                  <span className="h-1 w-1 rounded-full bg-white opacity-60" />
                                </>
                              )}
                              {!!usecase.totalUse && usecase.totalUse > 0 && (
                                <>
                                  <div className="flex items-center">
                                    <IconRun width={12} height={12} color="white" />
                                    <span className="ml-1 text-[12px] text-white">
                                      {formatCash(usecase.totalUse)}
                                    </span>
                                  </div>
                                  <span className="h-1 w-1 rounded-full bg-white opacity-60" />
                                </>
                              )}
                              {usecase?.totalViews > 0 && (
                                <>
                                  <div className="flex items-center">
                                    <IconCardTemplateEye />
                                    <span className="ml-1 text-[12px] text-white">
                                      {formatCash(usecase.totalViews)}
                                    </span>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>

                        {usecase.model?.isWorkflow && (
                          <p className="absolute left-2 top-2 z-[10] flex w-fit items-center justify-self-end rounded-[100px] bg-[rgba(18,18,18,0.50)] px-[6px] py-[2px] text-[14px] text-white backdrop-blur-[2px] transition-all sm:hidden">
                            <p className="px-1 text-12 font-semibold leading-5">AI Tool</p>
                          </p>
                        )}
                        {!usecase.model?.isWorkflow && (
                          <div className="absolute right-2 top-2 z-[20] flex items-center rounded-[100px] bg-gradient-to-r from-[#DA613A]  via-[#EB476B]  to-[#7D3DDC] px-2 py-[1px] sm:rounded-[4px] sm:px-1">
                            <p
                              className="flex items-center gap-1 text-[14px] font-semibold text-white sm:text-[13px]"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleUseUseCase(usecase.model.status, usecase.id, usecase);
                              }}
                            >
                              <IconRemixHover className="sm:hidden" /> Remix
                            </p>
                          </div>
                        )}
                      </Link>
                    </>
                  ))}
                </Masonry>
              </ResponsiveMasonry>
            </InfiniteScroll>
          </RenderWhen.If>
        </RenderWhen>
      </div>
      <LoginModal isOpen={isOpen} onClose={onClose} onOpenChange={onOpenChange} />
      {dataTemplateToView && (
        <Modal
          isOpen={isOpenViewTemplate}
          onClose={onCloseViewTemplate}
          classNames={{
            body: 'pt-5 px-5 pb-0 md:p-3 md:pb-0 h-full',
            base: 'max-w-[85%] h-[80%]',
            wrapper: 'items-center',
          }}
          scrollBehavior="inside"
          placement="center"
          hideCloseButton
        >
          <ModalContent>
            {(onCloseViewTemplate) => (
              <>
                <div
                  onClick={onCloseViewTemplate}
                  className="absolute right-[-10px] top-[-10px] z-50 flex h-[28px] w-[28px] cursor-pointer items-center justify-center rounded-full border-3 border-white bg-gray-50"
                >
                  <span>&#10005;</span>
                </div>
                <ModalBody>
                  <UsecaseDetail usecase={dataTemplateToView} isPopUp />
                </ModalBody>
              </>
            )}
          </ModalContent>
        </Modal>
      )}
    </div>
  );
};

export default ListUsecase;
