import { api } from '../axios';

export interface IFetchRoomMessages {
  size: number;
  offset: number;
}
export interface ISendMessages {
  content: string;
}

// function sleep(timeout: number): Promise<void> {
//   return new Promise((resolve) => {
//     setTimeout(resolve, timeout);
//   });
// }

export const fetchRoomMessage = async ({ size, offset }: IFetchRoomMessages) => {
  try {
    const response = await api.get(`/room-chat/history?size=${size}&offset=${offset}`);
    return [response.data, null];
  } catch (error) {
    return [null, error];
  }
};

export const postSendMessage = async ({ content }: ISendMessages) => {
  const data = JSON.stringify({
    content,
  });
  try {
    const response = await api.post(`/room-chat/chat`, data);
    // await sleep(1000);

    return [response.data, null];
  } catch (error) {
    return [null, error];
  }
};

export const getListMention = async (name: string) => {
  try {
    const response = await api.get(`/room-chat/mentions?name=${name}&size=5&offset=0`);

    return [response.data, null];
  } catch (error) {
    return [null, error];
  }
};
