import React, { useRef, useState } from 'react';
import { Input } from '@nextui-org/react';
import useLoadTag from '@/hooks/useLoadTag';
import { debounce } from 'lodash';
import { SelectOptionItem } from '@/components/select/SelectMultipleWithSearch';
import { makeid } from '@/utils';
import { useOnClickOutside } from '@/hooks/useOnClickOutside';

const AddTags: React.FC<{
  onClose?: () => void,
  onChange: (tag: SelectOptionItem) => void
}> = ({ onClose, onChange }) => {
  const refContainer = useRef(null);
  const [tags, setTags] = useState<SelectOptionItem[]>([]);
  const { loadTagOptions } = useLoadTag();
  useOnClickOutside(refContainer, () => onClose && onClose());

  const handleSearchTag = async (text: string) => {
    const result = await loadTagOptions(text);
    const data = result.some(item => item.label === text) ? result : [{ value: makeid(4), label: text }, ...result];
    setTags(data);
  }

  const handleClick = (tag: SelectOptionItem) => {
    onChange(tag);
    if (onClose) onClose();
  }

  return (
    <div className="relative" ref={refContainer}>
      <Input 
        classNames={{ 
          inputWrapper: 'bg-gray-50 min-h-[34px] rounded-full w-[200px]',
          input: 'bg-gray-50 text-[13px]',
          base: 'h-[34px]'
        }} 
        placeholder="Please input"
        onValueChange={debounce(handleSearchTag, 300)}
        autoFocus
      />
      {tags.length > 0 && (
        <div className="absolute top-[100%] left-0 bg-white shadow-md min-w-full max-w-[300px] text-[13px] rounded-md p-3">
          <ul className="max-h-[200px] overflow-y-auto scrollbar-custom scrollbar-w-small">
            {tags.map(tag => (
              <li 
                onClick={() => handleClick(tag)} 
                key={tag.value} 
                className="py-1 px-2 rounded-md hover:bg-gray-50 line-clamp-1 cursor-pointer font-medium"
              >
                {tag.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default AddTags;