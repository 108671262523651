import { Button, Popover, PopoverContent, PopoverTrigger } from '@nextui-org/react';
import React from 'react';
import { FacebookShareButton, TwitterShareButton } from 'react-share';

import { CopyLinkIcon, IconShareTemplate, ShareFacebookIcon, ShareTwitterIcon } from '@/assets';
import { configs } from '@/configs';
import useWindowSize from '@/hooks/useWindowSize';
import { copyClipboard } from '@/utils';

interface ShareButtonProps {
  titleShare: string;
  usecaseId: number;
}

const ShareButtonTemplate: React.FC<ShareButtonProps> = ({ titleShare, usecaseId }) => {
  const urlTemplate = `/template/${usecaseId}`;
  const { isMobileView } = useWindowSize();

  return (
    <Popover placement="left-start">
      <PopoverTrigger>
        {/* <Button className="mb-3 h-[44px] w-[44px] min-w-0 rounded-[100px] bg-white p-0">
          <IconShareTemplate />
        </Button> */}

        <Button className="rounded-[100px] border-[1px]" variant="flat" isIconOnly={isMobileView}>
          <span className="flex items-center gap-2 text-[15px] font-semibold">
            <IconShareTemplate />
            <span className="hidden group-hover:block lg:group-hover:hidden">Share</span>
          </span>
        </Button>
      </PopoverTrigger>

      <PopoverContent className="p-4">
        <div className="flex flex-col">
          <p
            onClick={(e) => copyClipboard(e, `${configs.ssoCallback}${urlTemplate}`)}
            className="m-0 mb-1 flex cursor-pointer items-center rounded-lg p-2 text-14 font-normal duration-300 hover:bg-gray-100"
          >
            <CopyLinkIcon className="mr-[10px]" /> Copy link
          </p>

          <TwitterShareButton
            hashtags={['aiRight', 'GenerativeAI', 'aiRightart']}
            url={`\n ${configs.ssoCallback}${urlTemplate} \n\n`}
            title={titleShare}
          >
            <p className="m-0 mb-1 flex cursor-pointer items-center rounded-lg p-2 text-14 font-normal duration-300 hover:bg-gray-100">
              <ShareTwitterIcon className="mr-[10px]" /> Share to X
            </p>
          </TwitterShareButton>

          <FacebookShareButton
            url={`\n ${configs.ssoCallback}${urlTemplate} \n\n`}
            title={titleShare}
          >
            <p className="m-0 mb-1 flex cursor-pointer items-center rounded-lg p-2 text-14 font-normal duration-300 hover:bg-gray-100">
              <ShareFacebookIcon className="mr-[10px]" /> Share to Facebook
            </p>
          </FacebookShareButton>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default ShareButtonTemplate;
