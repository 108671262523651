'use client';

import { Button, Divider, Image, ScrollShadow, useDisclosure } from '@nextui-org/react';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import {
  HeartBuyPromptActiveIcon,
  HeartBuyPromptIcon,
  IconDownloadImage,
  IconEditBlack,
  IconEyeTemplate,
  IconIsLikeTemplate,
  IconMagic,
  IconNotlikeTemplate,
  IconRemixTemplate,
  IconRemovePost,
  IconVerify16,
} from '@/assets';
import { AccordionBaseSingle, AccordionTransparent, Buttons, Images } from '@/components';
import {
  ActiveBookMarkDetailIcon,
  BookMarkDetailIcon,
  BookMarkDetailIconIsSet,
  DownIndicatorAccordionIcon,
  IconDot,
  NoActiveBookMarkDetailIcon,
} from '@/components/assets';
import AvatarPlaceholder from '@/components/avatar-default';
import ShareButtonTemplate from '@/components/button/ShareButtonTemplate';
import CommentTemplate from '@/components/comment-template';
import NsfwMark from '@/components/NsfwMark';
import PromptConfig from '@/components/prompt-config';
import ViewFullMedia from '@/components/view-media';
import { MIXPANEL_TRACK } from '@/configs/constant';
import useWindowSize from '@/hooks/useWindowSize';
import EditTemplate from '@/layouts/edit-template';
import LoginModal from '@/layouts/header/Login';
import RemixCommon from '@/layouts/remix-common';
import { useLayout } from '@/provider/LayoutProvider';
import { useRouter } from '@/provider/RouterProvider';
import { useAuth } from '@/provider/UserProvider';
import {
  apiAddBookMark,
  apiUnBookMark,
  getUsecaseById,
  likeTemplate,
  postIncreaseTotalUse,
  postUnpublishTemplalte,
  unLikeTemplate,
} from '@/services/usecase';
import type { IUsecaseItem } from '@/types/usecase';
import { downloadImage, formatNumberSocial, getUrlDonate, useSMutation } from '@/utils';

import ListUsecase from '../ListUsecase';
import useUseCaseSimilar from '../useUseCaseSimilar';
import { ArtworkDonateType } from '@/types';

const UsecaseDetail: React.FC<{
  usecase: any;
  isPopUp?: boolean;
}> = ({ usecase, isPopUp }) => {
  const { mutate: mutateLike } = useSMutation({ mutationFn: likeTemplate });
  const { mutate: mutateUnlike } = useSMutation({
    mutationFn: unLikeTemplate,
  });
  const { isLogin, auth, isLoginClient } = useAuth();
  const { isOpen, onOpenChange, onClose } = useDisclosure();
  const {
    isOpen: isOpenEditTemplate,
    onOpenChange: onOpenChangeEditTemplate,
    onClose: onCloseEditTemplate,
    onOpen: onOpenEditTemplate,
  } = useDisclosure();

  const [remixConfig, setRemixConfig] = useState<IUsecaseItem | null>(null);
  const useModalRemix = useDisclosure({
    onClose() {
      document.body.style.removeProperty('overflow');
      setRemixConfig(null);
    },
  });
  const { onOpen } = useModalRemix;

  const router = useRouter();
  const { isMobileView } = useWindowSize();
  const { setIsLoadingBar } = useLayout();
  const getUrlUsecaseCategory = (tag: string) => `/template/category/${tag}`;
  const isOwner = usecase?.creator?.publicAddress === auth.user.publicAddress;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');
  const [currentType, setCurrentType] = useState('');
  const [isLoadingAddBookMark, setIsLoadingAddBookMark] = useState(false);

  const { list, loading, currentPage, loadMore } = useUseCaseSimilar(usecase?.id);

  const [addBookMark, setAddBookMark] = useState(usecase?.attributes?.isBookmarked);
  const [numberBookMark, setNumberBookMark] = useState(usecase?.totalBookmarks || 0);

  const [isLike, setIsLike] = useState(usecase?.attributes?.isLiked || false);
  const [numLike, setNumLike] = useState(usecase?.attributes?.numberOfLikes || 0);
  const handleUnlike = () => {
    if (!isLogin) return toast.error('You must login to continue!');
    setIsLike(!isLike);
    mutateUnlike(usecase.id);
    setNumLike((val: number) => val - 1);
  };

  const handleLike = () => {
    if (!isLogin) return toast.error('You must login to continue!');
    setIsLike(!isLike);
    mutateLike(usecase.id);
    setNumLike((val: number) => val + 1);
  };

  const handleAddBookMark = async (addBookMark: boolean) => {
    if (!isLogin) return toast.error('You must login to continue!');
    setIsLoadingAddBookMark(true);
    if (addBookMark) {
      await apiUnBookMark(usecase?.id);
      setAddBookMark(false);
      setNumberBookMark((val: number) => val - 1);
    } else {
      await apiAddBookMark(usecase?.id);
      setAddBookMark(true);
      setNumberBookMark((val: number) => val + 1);
    }
    setIsLoadingAddBookMark(false);
  };

  // const openModal = (src: any, type: any) => {
  //   setIsModalOpen(true);
  //   setCurrentImage(src);
  //   setCurrentType(type);
  // };
  console.log('usesss', usecase);

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentImage('');
    setCurrentType('');
  };

  const handleDownloadImage = async (url: string) => {
    toast.warning('Downloading ...');
    mixpanel.track(MIXPANEL_TRACK.DOWNLOAD_TEMPLATE);
    await downloadImage(url, 'aiRight');
  };

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };

    if (isModalOpen) {
      document.addEventListener('keydown', handleKeyPress);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [isModalOpen]);

  const handleCheckIsLike = async () => {
    const res = await getUsecaseById(usecase.id);
    if (res) {
      setIsLike(res.data?.attributes?.isLiked || false);
      setNumLike(res.data?.attributes?.numberOfLikes || 0);
    }
  };
  useEffect(() => {
    if (isLoginClient && !isPopUp) {
      handleCheckIsLike();
    }
  }, [isPopUp, isLoginClient]);

  const handleUseUseCase = (status: number, usecaseId: number, remixConfig: IUsecaseItem) => {
    postIncreaseTotalUse(usecaseId);
    if (status === 4) {
      return toast.error('Model maintenance!');
    }
    mixpanel.track(MIXPANEL_TRACK.REMIX);
    setRemixConfig(remixConfig);
    document.body.style.overflow = 'hidden';
    onOpen();
  };
  const handelUnPublishTemplate = async (templateId: any) => {
    const res = await postUnpublishTemplalte(templateId);
    if (res.data.message === 'success') {
      toast.success('Successfully removed post');
    }
    // router.push(`/profile/${auth.user.publicAddress}`);
    router.back();
  };

  return (
    <section className={`${isPopUp ? '' : 'mx-auto max-w-[1480px]'} h-[100%]`}>
      {remixConfig && <RemixCommon useModalRemix={useModalRemix} remixConfig={remixConfig} />}

      <div
        className={`flex ${
          isPopUp ? 'h-fit' : 'px-5 pt-[40px] xl:pt-5'
        } gap-5 xl:h-auto xl:flex-col md:gap-3`}
      >
        <div className={`flex w-[70%] flex-col xl:h-full xl:w-full xl:p-0 `}>
          <div className="flex h-[100%] w-[100%] flex-col gap-3 object-contain md:flex-col-reverse">
            <div className="flex justify-between gap-2 md:flex-row-reverse">
              <div className="flex items-center gap-[10px] md:gap-1">
                <Buttons
                  onClick={isLike ? handleUnlike : handleLike}
                  typeBtn={isLike ? 'secondary' : 'cancel'}
                  className={`h-[40px] w-[40px] min-w-0 rounded-[100px] border-[1px] p-0 ${
                    !isLike ? 'bg-gray-50' : ''
                  }`}
                >
                  {isLike ? <HeartBuyPromptActiveIcon /> : <HeartBuyPromptIcon />}
                </Buttons>
                <Buttons
                  onClick={() => !isLoadingAddBookMark && handleAddBookMark(addBookMark)}
                  typeBtn={addBookMark ? 'secondary' : 'cancel'}
                  className={`h-[40px] w-[40px] min-w-0 rounded-[100px] border-[1px] p-0 ${
                    !addBookMark ? 'bg-gray-50' : ''
                  }`}
                >
                  {addBookMark ? <ActiveBookMarkDetailIcon /> : <NoActiveBookMarkDetailIcon />}
                </Buttons>
              </div>
              <div className="flex items-center gap-[5px]">
                {isOwner && (
                  <Button
                    className="group rounded-[100px] border-[1px]"
                    onClick={() => onOpenEditTemplate()}
                    variant="flat"
                    isIconOnly={isMobileView}
                  >
                    <span className="flex items-center gap-2 text-[15px] font-semibold">
                      <IconEditBlack />
                      <span className="hidden group-hover:block xl:group-hover:hidden">Edit</span>
                    </span>
                  </Button>
                )}
                {isOwner && (
                  <Button
                    className="rounded-[100px] border-[1px]"
                    onClick={() => {
                      handelUnPublishTemplate(`${usecase.id}`);
                    }}
                    variant="flat"
                    isIconOnly={isMobileView}
                  >
                    <span className="flex items-center gap-2 text-[15px] font-semibold">
                      <IconRemovePost />
                      <span className="hidden group-hover:block xl:group-hover:hidden">Remove</span>
                    </span>
                  </Button>
                )}
                <Button
                  className="rounded-[100px] border-[1px]"
                  onClick={() => {
                    handleDownloadImage(`${usecase.processingAsset?.originalUrl}`);
                  }}
                  variant="flat"
                  isIconOnly={isMobileView}
                >
                  <span className="flex items-center gap-2 text-[15px] font-semibold">
                    <IconDownloadImage />
                    <span className="hidden group-hover:block xl:group-hover:hidden">Download</span>
                  </span>
                </Button>
                <ShareButtonTemplate
                  titleShare={`✨ Transform your imagination to mesmerizing AI Art with airight.io! \n\n Remix my artwork to start now:\n`}
                  usecaseId={usecase.id}
                />
              </div>
            </div>
            <div className="relative h-full max-h-[65vh] flex-1 overflow-hidden rounded-[12px] xl:w-full">
              <Images
                imageWrapper="absolute top-0 left-0"
                className="object-cover blur-[100px]"
                src={usecase.processingAsset?.originalUrl}
                alt={usecase.processingAsset?.originalUrl}
              />
              <Images
                className="max-h-[65vh] cursor-pointer object-contain xl:max-h-[500px] md:max-h-[400px]"
                zoom
                src={usecase.processingAsset?.originalUrl}
                alt={usecase.processingAsset?.originalUrl}
              />
              {usecase.processingAsset?.isNsfw && (
                <NsfwMark url={usecase.processingAsset?.originalUrl} />
              )}
            </div>
          </div>
        </div>
        <div className="w-[30%] flex-col rounded-xl border-[1px] p-4 xl:h-full xl:w-full xl:py-2">
          <ScrollShadow
            size={10}
            hideScrollBar
            className="h-[calc(65vh-34px)] flex-1 overflow-y-auto scrollbar-hide xl:h-[400px] sm:flex-col"
          >
            <div className="flex w-full flex-col gap-4 pb-3">
              <div className="flex items-center justify-between">
                <Link
                  aria-checked={Boolean(usecase.creator.avatarDecoration)}
                  href={`/profile/${usecase.creator.publicAddress}`}
                  onClick={() => setIsLoadingBar(true)}
                  className="flex items-center gap-2 aria-checked:pt-1 aria-checked:pl-1"
                >
                  <div className="h-[44px] w-[44px] rounded-[50%] relative">
                    <AvatarPlaceholder
                      src={usecase.creator.picture}
                      address={usecase.creator.publicAddress}
                      className="h-full w-full rounded-[50%] object-cover"
                      decor={usecase.creator.avatarDecoration}
                      decorScale={25}
                    />
                  </div>
                  <div className="flex max-w-[calc(100%-52px)] flex-col text-start">
                    <div className="flex items-center text-[14px] font-semibold text-black">
                      <p className="line-clamp-1">{usecase.creator?.username}</p>
                      {usecase.creator.sVerify === 'success' && (
                        <span className="">
                          <IconVerify16 />
                        </span>
                      )}
                    </div>
                    <span className="text-[14px] sm:text-[12px] text-gray-500">
                      Updated: {moment(usecase.createdAt, 'YYYYMMDD').fromNow()}
                    </span>
                  </div>
                </Link>
                {!isOwner && (
                  <Link 
                    target="_blank" 
                    className="px-3 py-1 rounded-full bg-orange-50 text-orange-500 font-semibold text-[14px]" 
                    href={getUrlDonate(usecase.creator.publicAddress, usecase.id, ArtworkDonateType.Template, auth.isOwallet)}
                  >
                    Donate
                  </Link>
                )}
              </div>
              <div className="relative w-full rounded-xl bg-gray-50 p-[6px] py-[12px] pl-4">
                <div className="max-w-[70%] items-center pb-[10px] text-[20px] font-semibold text-black xl:text-[16px]">
                  {usecase.name && <span className="text-black">{usecase.name}</span>}
                  <span className="text-gray-500"> #{usecase.id}</span>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div
                      className="relative flex cursor-pointer items-center gap-[3px] pr-[3px] text-[12px]"
                      onClick={isLike ? handleUnlike : handleLike}
                    >
                      {isLike ? <IconIsLikeTemplate /> : <IconNotlikeTemplate />} {numLike}
                    </div>
                    <IconDot />
                    <div
                      className="relative flex cursor-pointer items-center gap-[3px] pr-[3px] text-[12px]"
                      onClick={() => !isLoadingAddBookMark && handleAddBookMark(addBookMark)}
                    >
                      {addBookMark ? <BookMarkDetailIconIsSet /> : <BookMarkDetailIcon />}
                      {numberBookMark}
                    </div>
                    <IconDot />
                    <div className="pr-[3px]">
                      <IconRemixTemplate />
                    </div>
                    <span className="font-sans text-[12px]">
                      {formatNumberSocial(usecase?.totalUse) || '--'}
                    </span>
                    <IconDot />
                    <div className="pr-[3px]">
                      <IconEyeTemplate />
                    </div>
                    <span className="font-sans text-[12px]">
                      {formatNumberSocial(usecase?.totalViews) || '--'}
                    </span>
                  </div>
                </div>
              </div>
              {usecase.description && (
                <AccordionTransparent
                  openDefault
                  indicator={false}
                  title={
                    <div className="flex items-center justify-end text-[14px] font-semibold text-black">
                      Description <DownIndicatorAccordionIcon className="scale-75" />
                    </div>
                  }
                >
                  <div className="text-[14px] leading-[1.7] text-[#505665] [overflow-wrap:anywhere]">
                    {usecase.description}
                  </div>
                </AccordionTransparent>
              )}
            </div>

            <Divider className="bg-gray-100" />
            <CommentTemplate templateId={usecase.id} />
            {usecase.model && (
              <>
                <Divider className="mb-3 bg-gray-100" />

                <div>
                  <div className="pb-3 text-16 font-semibold leading-7">
                    {usecase.model?.isWorkflow ? 'AI Tool' : 'Model used'}
                  </div>
                  {usecase.model?.isWorkflow ? (
                    <Link
                      className="flex max-w-[400px] items-center rounded-xl bg-gray-50 px-2 py-3"
                      href={isLogin ? `/showcase/${usecase?.model?.id}` : ''}
                      onClick={() => setIsLoadingBar(true)}
                    >
                      <Image
                        src={usecase.model?.previews && usecase.model?.previews[0]}
                        alt="Model Picture"
                        className="h-[44px] w-[44px] rounded-lg object-cover"
                      />
                      <div className="pl-3 text-14 ">
                        <p className="font-semibold">{usecase.model?.name}</p>
                      </div>
                    </Link>
                  ) : (
                    <Link
                      className="flex max-w-[400px] items-center rounded-xl bg-gray-50 px-2 py-3"
                      href={isLogin ? `/ai-model/${usecase?.model?.id}/generate` : ''}
                      onClick={() => setIsLoadingBar(true)}
                    >
                      <Image
                        src={usecase.model?.picture}
                        alt="Model Picture"
                        className="h-[44px] w-[44px] rounded-lg object-cover"
                      />
                      <div className="pl-3 text-14 ">
                        <p className="font-semibold">{usecase.model?.name}</p>
                        <p className="text-gray-500">{usecase.model?.type}</p>
                      </div>
                    </Link>
                  )}
                </div>
              </>
            )}
            {!usecase?.model?.isWorkflow && (
              <>
                <Divider className="my-3 bg-gray-100" />
                <div className="xl:pb-10">
                  <AccordionBaseSingle
                    className="mb-0 bg-white"
                    title={
                      <span className="pb-3 text-16 font-semibold leading-7">Generation Data</span>
                    }
                  >
                    <PromptConfig
                      className="w-full !p-0"
                      classNameConfig="!grid-cols-2"
                      prompt={usecase.prompt?.prompt || usecase.prompt?.displayPrompt}
                      configString={usecase.processingAsset.modelConfig}
                    />
                  </AccordionBaseSingle>
                </div>
              </>
            )}
            {usecase.templateTags.length > 0 && (
              <>
                <Divider className="my-3 bg-gray-100" />
                <div className="mt-3 flex flex-wrap pb-5">
                  {usecase.templateTags.map((item: any) => (
                    <Link
                      key={item.tag.id}
                      onClick={() => router.push(getUrlUsecaseCategory(item.tag.content))}
                      className="mb-2 mr-2 rounded-[100px] bg-blue-50 px-3 py-1 text-[14px] lowercase text-blue-500"
                      href={getUrlUsecaseCategory(item.tag.content)}
                    >
                      #{item.tag.content}
                    </Link>
                  ))}
                </div>
              </>
            )}
          </ScrollShadow>
          <div className="z-[30] w-full bg-white pt-3 xl:sticky xl:bottom-0 xl:left-0 xl:-mx-4 xl:ml-0 xl:w-full xl:p-2">
            <div className="md:flex md:items-center md:justify-start">
              {usecase &&
                (usecase.model?.isWorkflow ? (
                  <Buttons
                    className="w-full p-0"
                    onClick={() => {
                      postIncreaseTotalUse(usecase.id);
                      window.open(`/showcase/${usecase.model?.id}`, '_self');
                    }}
                  >
                    <IconMagic />
                    <span>Remix</span>
                  </Buttons>
                ) : (
                  <Buttons
                    className="w-full p-0"
                    onClick={() => handleUseUseCase(usecase.model?.status, usecase.id, usecase)}
                  >
                    <IconMagic />
                    <span>Remix</span>
                  </Buttons>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${
          isPopUp ? 'py-[20px] xl:py-[10px] ' : 'px-5 py-[20px] xl:px-2 xl:py-[10px]'
        } md:flex-col`}
      >
        <div className="pb-1 text-[20px] font-semibold">Related Templates</div>
        {list.length > 1 && (
          <ListUsecase
            openFilter={false}
            setOpenFilter={() => {}}
            list={list}
            loadMore={loadMore}
            currentPage={currentPage}
            loading={loading}
            isViewInUrl
          />
        )}
      </div>

      <LoginModal isOpen={isOpen} onOpenChange={onOpenChange} onClose={onClose} />
      {currentType.includes('image') && (
        <ViewFullMedia isOpen={isModalOpen} onClose={closeModal} url={currentImage} />
      )}
      <EditTemplate
        templateId={usecase.id}
        imageUrl={usecase.processingAsset?.originalUrl || ''}
        name={usecase.name}
        description={usecase.description}
        tags={usecase.templateTags || []}
        isOpen={isOpenEditTemplate}
        onOpenChange={onOpenChangeEditTemplate}
        onClose={onCloseEditTemplate}
      />
    </section>
  );
};

export default UsecaseDetail;
